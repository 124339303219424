<template>
    <div>
        <div class="col-md-12">
            <div class="row" style="margin-top:20px">
                <div class="col-md-6">
                    <h3 class="page-header">
                        <i class="fa fa fa-user animated bounceInDown show-info"></i>
                        Employee Ledger Detail
                    </h3>
                </div>
                <div class="col-md-6 text-end">
                    <button class="btn btn-primary" @click="backBtn()">Back</button>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-3">
                            <label for="fromdate">From Date :</label>
                            <input type="date" class="form-control" id="fromdate" v-model="fromdate">
                        </div>
                        <div class="col-md-3">
                            <label for="todate">From Date :</label>
                            <input type="date" class="form-control" id="todate" v-model="todate">
                        </div>
                        <div class="col-md-3 on_bottom">
                            <button class="btn btn-primary"> Fetch Data</button>
                        </div> 
                    </div>
                </div>

            </div>
        </div>
        <div class="col-md-12">
            <div class="panel">
                <div class="panel-body" style="background: none;">
                    <div>

                        <table class="table table-bordered table-hover table-striped display" id="tblmanual">
                            <thead class="table-font" style="background: #475c78;color: #fff;">
                                <th class="visible-lg">#</th>
                                <th>Type</th>
                                <th>Last Transaction</th>
                                <th>Balance</th>
                            </thead>
                            <tbody class="table-font">
                                <tr v-for="(item, index) in ledgerDetails" :key="item.id">
                                    <td>{{ index + 1 }}</td>
                                    <td>
                                        <span v-if="item.trantype==33">Salary In</span>
                                    </td>
                                    <td>{{ item.transactiondate }}</td>
                                    <td>{{ item.balance }}</td>
                                   
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <!-- <div class="col-md-12 mt-20" >
                            <nav aria-label="Page navigation example" style="text-align: right !important">
                                <paginate style="margin: 0px !important; float: right" :page-count="noofpages"
                                    :click-handler="clickCallback" :prev-text="'&laquo;'" :next-text="'&raquo;'"
                                    :container-class="'pagination'">
                                </paginate>
                            </nav>
                        </div> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import { mapGetters } from 'vuex'
import axios from 'axios'
// import Paginate from "vuejs-paginate";
import moment from 'moment';
// import Constants from '../../components/utilities/Constants.vue';
import Constants from '../../components/utilities/Constants.vue';
export default {
    // components: {    Paginate },
    mixins: [Constants],
    // props: ['id'],
    data() {
        return {
            currentpage: 1,
            noofrec: 10,
            ledgerDetails: [],
            userdetail: {},
            fromdate: new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().slice(0, 10),
			todate: new Date().toISOString().slice(0, 10),
            userid: 0,
            moment: moment,
        }
    },
    computed: {
        // ...mapGetters(['selectedstoreid']),
        // noofpages() {
        //     return Math.ceil(parseInt(this.rowcount) / parseInt(this.noofrec))
        // }
    },

    mounted() {
        this.userid = this.$route.params.id
        this.refresh();
    },
    methods: {
        clickCallback(currentpage) {
            this.currentpage = currentpage
        },
        refresh() {
            let param = { userid: this.userid,fromdate:this.fromdate, todate :this.todate };
            axios.post('api/web/ledger/details', param)
                .then(response => this.processResponseLedgerDetail(response.data))
                .catch(error => console.log(error))
        },
        processResponseLedgerDetail(data){
            this.ledgerDetails = data.details
            this.userdetail = data.userdetail
        },
        backBtn(){
            this.$router.push({ name: 'employee-ledger' });
        }
    }
}
</script>

<style scoped>
.btn-primary:hover {
    text-decoration: none !important;
    background-color: #3a476b !important;
}
</style>